import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                'https://sandefjordkampsport-backend.srv01.nuva.no/login',
                { email, password },
                { headers: { 'Content-Type': 'application/json' } }
            );
            const token = response.data.token;
            localStorage.setItem('token', token);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 space-y-6 bg-white rounded shadow">
                <div className="flex flex-col items-center justify-center ">
                    <img src="/assets/logo.png" alt="Logo" className="w-32 h-32"/>
                    <h1 className="mt-4 text-3xl font-bold text-center ">Sandefjord Kampsport</h1>
                </div>
                <form onSubmit={handleLogin} className="space-y-4">
                    <div>
                        <label className="block">E-post</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block">Passord</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-2 border rounded"
                            required
                        />
                    </div>
                    <button type="submit" className="w-full p-2 font-bold text-black rounded bg-custom-green">
                        Login
                    </button>
                </form>
                <div className="flex justify-center mt-4">
                    <Link to="/register" className="text-blue-500">Har du ingen konto? Registrer deg her!</Link>
                </div>
            </div>
        </div>
    );
};

export default Login;
