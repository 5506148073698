import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Navbar from './Navbar';

Modal.setAppElement('#root'); // For accessibility

const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('https://sandefjordkampsport-backend.srv01.nuva.no/users', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleUpdateUser = async () => {
        if (!selectedUser) return;

        try {
            await axios.patch(`https://sandefjordkampsport-backend.srv01.nuva.no/user/${selectedUser.id}`, {
                paid: isPaid,
                admin: isAdmin
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            fetchUsers(); // Refresh the user list after updating
            alert('User updated successfully');
            closeModal();
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const openModal = (user) => {
        setSelectedUser(user);
        setIsPaid(user.paid);
        setIsAdmin(user.admin);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedUser(null);
        setModalIsOpen(false);
    };

    return (
        <div className="flex flex-col h-screen bg-gray-100 pt-16">
            <Navbar />
            <div className="p-4 flex-grow">
                <h2 className="text-2xl font-bold text-center mb-4">Manage Users</h2>
                <ul className="bg-white shadow rounded-lg divide-y divide-gray-200">
                    {users.map((user) => (
                        <li
                            key={user.id}
                            className="flex items-center justify-between p-4 hover:bg-gray-100 cursor-pointer"
                            onClick={() => openModal(user)}
                        >
                            <div className="text-lg font-medium">{user.firstname} {user.lastname}</div>
                            <div className="text-sm text-gray-500">{user.email}</div>
                        </li>
                    ))}
                </ul>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="User Details"
                    className="bg-white p-6 rounded shadow-lg max-w-2xl mx-auto mt-20"
                    overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
                >
                    {selectedUser && (
                        <div>
                            <h2 className="text-xl font-bold mb-4">Rediger bruker</h2>
                            <p className="mb-2"><strong>Navn:</strong> {selectedUser.firstname} {selectedUser.lastname}</p>
                            <p className="mb-2"><strong>E-post:</strong> {selectedUser.email}</p>
                            <p className="mb-4"><strong>Sport:</strong> {selectedUser.sport}</p>
                            <div className="mt-4">
                                <label className="flex items-center mb-2">
                                    <input
                                        type="checkbox"
                                        checked={isPaid}
                                        onChange={(e) => setIsPaid(e.target.checked)}
                                    />
                                    <span className="ml-2">Betalt</span>
                                </label>
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={isAdmin}
                                        onChange={(e) => setIsAdmin(e.target.checked)}
                                    />
                                    <span className="ml-2">Admin</span>
                                </label>
                            </div>
                            <div className="mt-4 flex justify-end">
                                <button onClick={closeModal} className="p-2 bg-gray-500 text-white rounded mr-2">
                                    Lukk
                                </button>
                                <button
                                    onClick={handleUpdateUser}
                                    className="p-2 bg-blue-500 text-white rounded"
                                >
                                    Lagre
                                </button>
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        </div>
    );
};

export default ManageUsers;
