import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [sport, setSport] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://sandefjordkampsport-backend.srv01.nuva.no/register', {
                firstname,
                lastname,
                phone,
                email,
                password,
                sport
            });
            alert('Registration successful! You can now log in.');
            navigate('/');
        } catch (error) {
            console.error('Error registering:', error);
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 space-y-6 bg-white rounded shadow">
                <div className="flex flex-col items-center justify-center ">
                    <img src="/assets/logo.png" alt="Logo" className="w-32 h-32"/>
                </div>
                <h2 className="text-2xl font-bold">Lag konto</h2>
                <form onSubmit={handleRegister} className="space-y-4">
                    <div>
                        <label className="block">Fornavn</label>
                        <input
                            type="text"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            className="w-full p-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block">Etternavn</label>
                        <input
                            type="text"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            className="w-full p-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block">Telefon</label>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="w-full p-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block">E-post</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block">Passord</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-2 border rounded"
                            required
                        />
                    </div>
                    <div>
                        <label className="block">Sport</label>
                        <select
                            value={sport}
                            onChange={(e) => setSport(e.target.value)}
                            className="w-full p-2 border rounded"
                            required
                        >
                            <option value="">Velg sport</option>
                            <option value="BJJ">BJJ</option>
                            <option value="Boxing">Boxing</option>
                            <option value="Judo">Judo</option>
                            <option value="Thai Boxing">Thai Boxing</option>
                        </select>
                    </div>
                    <button type="submit" className="w-full p-2 font-bold text-black rounded bg-custom-green">
                        Registrer
                    </button>
                </form>
                <button
                    onClick={() => navigate(-1)}
                    className="mt-4 w-full p-2 font-bold text-white bg-gray-500 rounded"
                >
                    Gå tilbake
                </button>
            </div>
        </div>
    );
};

export default Register;
