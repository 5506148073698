import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState('Updating payment status...');
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.id;

    useEffect(() => {
        const updatePaymentStatus = async () => {
            try {
                const response = await fetch('https://sandefjordkampsport-backend.srv01.nuva.no/update-payment-status', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId }),
                });

                if (response.ok) {
                    setMessage('Payment status updated successfully');

                    // Fetch a new token
                    const newTokenResponse = await fetch('https://sandefjordkampsport-backend.srv01.nuva.no/get-new-token', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userId }),
                    });

                    if (newTokenResponse.ok) {
                        const newTokenData = await newTokenResponse.json();
                        localStorage.setItem('token', newTokenData.token);
                    } else {
                        setMessage('Failed to fetch new token');
                    }
                } else {
                    setMessage('Failed to update payment status');
                }
            } catch (error) {
                setMessage(`Error updating payment status: ${error.message}`);
            }
        };

        updatePaymentStatus();
    }, [userId]);

    const handleBackToDashboard = () => {
        navigate('/dashboard');
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h2 className="text-2xl font-bold text-center mb-4">Betaling gjennomført!</h2>
            <button
                onClick={handleBackToDashboard}
                className="mt-4 px-4 py-2 bg-green-500 text-white font-semibold rounded"
            >
                Se din QR kode til døren
            </button>
        </div>
    );
};

export default PaymentSuccess;