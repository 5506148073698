import React from 'react';
import { Link } from 'react-router-dom';

const PaymentCancel = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h2 className="text-2xl font-bold text-center mb-4">Payment Cancelled</h2>
            <p className="text-center">Your payment was cancelled. Please try again.</p>
            <Link to="/dashboard" className="mt-4 text-blue-500 underline">Return to Dashboard</Link>
        </div>
    );
};

export default PaymentCancel;
