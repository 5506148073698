import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Navbar = () => {
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const isAdmin = decoded.admin;

    return (
        <nav className="bg-custom-green p-4 shadow-md fixed w-full z-50">
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/dashboard" className="text-white text-lg font-semibold">
                    Dashboard
                </Link>
                {isAdmin && (
                    <div className="relative group">
                        <button className="text-white text-lg font-semibold">
                            Admin
                        </button>
                        <div className="absolute right-0 hidden group-hover:block bg-white text-black rounded shadow-lg mt-2 w-48">
                            <Link to="/admin-dashboard" className="block px-4 py-2 hover:bg-gray-200">Admin Dashboard</Link>
                            <Link to="/manage-users" className="block px-4 py-2 hover:bg-gray-200">Rediger medlemmer</Link>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navbar;