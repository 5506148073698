import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_test_51PbMJsRpVgCPYTthHERoofxRtePlM8QbE8vBzhEI1FoBtXDZLjkNtDyq0nQyPOPzWBVl3J5LFzA2X0ZriqYZee6T00z9nRFVUw');

const Dashboard = () => {
    const [stripeUrl, setStripeUrl] = useState('');
    const [isPaid, setIsPaid] = useState(false);
    const navigate = useNavigate();

    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const userId = decoded.id;

    useEffect(() => {
        const checkPaymentStatus = async () => {
            try {
                const response = await fetch('https://sandefjordkampsport-backend.srv01.nuva.no/get-payment-status', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ userId })
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.paid !== decoded.paid) {
                        const newTokenResponse = await fetch('https://sandefjordkampsport-backend.srv01.nuva.no/get-new-token', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ userId })
                        });

                        if (newTokenResponse.ok) {
                            const newTokenData = await newTokenResponse.json();
                            localStorage.setItem('token', newTokenData.token);
                            setIsPaid(true); // Ensure the updated status is reflected
                        }
                    } else {
                        setIsPaid(decoded.paid);
                    }
                }
            } catch (error) {
                console.error('Error checking payment status:', error);
            }
        };

        checkPaymentStatus();
    }, [navigate, token, userId, decoded.paid]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    const handlePayment = async () => {
        const response = await fetch('https://sandefjordkampsport-backend.srv01.nuva.no/create-checkout-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId }),
        });
        const data = await response.json();
        setStripeUrl(data.url);
    };

    useEffect(() => {
        if (stripeUrl) {
            window.location.href = stripeUrl;
        }
    }, [stripeUrl]);

    return (
        <Elements stripe={stripePromise}>
            <div className="flex flex-col h-screen bg-gray-100">
                <Navbar />
                <div className="flex flex-col items-center justify-center flex-grow p-4">
                    <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md mb-4">
                        <h2 className="text-2xl font-bold text-center mb-4">Dashboard</h2>
                        {isPaid ? (
                            <div className="flex flex-col items-center">
                                <QRCode value={userId} size={256} />
                            </div>
                        ) : (
                            <div className="text-center">
                                <p className="mt-4 text-center text-red-500">
                                    Kontoen er enda bekreftet, en administrator vil aktivere den så fort som mulig.
                                </p>
                                <button
                                    onClick={handlePayment}
                                    className="mt-4 w-full p-2 font-bold text-white bg-blue-500 rounded"
                                >
                                    Pay for membership
                                </button>
                            </div>
                        )}
                        <button
                            onClick={handleLogout}
                            className="mt-4 w-full p-2 font-bold text-white bg-red-500 rounded"
                        >
                            Logg ut
                        </button>
                    </div>
                </div>
            </div>
        </Elements>
    );
};

export default Dashboard;