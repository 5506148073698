import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const AdminDashboard = () => {
    const [stats, setStats] = useState(null);
    const token = localStorage.getItem('token');
    const decoded = jwtDecode(token);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await axios.get('https://sandefjordkampsport-backend.srv01.nuva.no/stats', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setStats(response.data);
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };

        fetchStats();
    }, []);

    const sportData = stats
        ? Object.entries(stats.sportCounts).map(([sport, count]) => ({ name: sport, value: count }))
        : [];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

    return (
        <div className="flex flex-col h-screen bg-gray-100">
            <Navbar />
            <div className="flex flex-col items-center justify-center flex-grow p-4 pt-20">
                <div className="w-full space-y-6">
                    <div className="w-full bg-white p-4 rounded-lg shadow-md">
                        <h2 className="text-xl font-bold mb-4">Antall brukere</h2>
                        <div className="w-full h-64">
                            <ResponsiveContainer>
                                <BarChart data={[stats]}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="totalUsers" fill="#8884d8" name="Antall medlemmer" />
                                    <Bar dataKey="paidUsers" fill="#82ca9d" name="Betalende medlemmer"/>
                                    <Bar dataKey="unpaidUsers" fill="#ffc658" name="Ubetalte medlemmer" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>

                    <div className="w-full bg-white p-4 rounded-lg shadow-md">
                        <h2 className="text-xl font-bold mb-4">Sport</h2>
                        <div className="w-full h-64">
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={sportData}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius="80%"
                                        fill="#8884d8"
                                        label
                                    >
                                        {sportData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;